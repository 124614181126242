/* eslint-disable no-eval */
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getAxiosHeader, updateBasicDetails } from '../../Config/api';
import { UIStore } from '../../Config/store';

import { CancelOutlined } from '@mui/icons-material';
import { axiosBaseInstance } from '../../Config/axios';

import { sideUi } from '../../Helper/SideUi/index';
import { displayTime, isDateValid } from '../../Helper/dates';
import { trimFields } from '../../Helper/helpers';
import { useIsDesktop } from '../../Helper/hooks';
import { statesEnum } from '../../Utils/enums';
import { ConstructFormInputs } from './form/constructFormInputs';

export default function UserInput({ }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const form = UIStore.useState((s) => s.form);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  // const fieldContent = UIStore.useState((s) => s.fieldContent);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const [recordsSet, setRecordsSet] = useState([{}]);
  const [markRecordsAsDone, setMarkRecordsAsDone] = useState({});
  const [bodySentForSendToProcess, setBodySentForSendToProcess] = useState({});
  const [bodySentForUpdateTheIdentificationValues, setBodySentForUpdateTheIdentificationValues] = useState({});
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgvOpted, setBgvOpted] = useState(false);
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formResponses, setFormResponses] = useState([]);
  const userInput = UIStore.useState((s) => s.userInput);
  const data = userInput;
  const c = data?.additionalInfo?.authOptions?.[0]?.page?.config;
  const [disabledFields, setDisabledFields] = React.useState({});
  const [edit, setEdit] = useState(false);
  const [identificationInfo, setIdentificationInfo] = useState(null);
  const [nonVerificationFields, setNonVerificationFields] = useState([]);
  const [staleData, setStaleData] = useState({});


  const shouldShowEditButton = () => {
    // if state is closed, dont show
    if ([form?.state].includes(statesEnum.CLOSED)) {
      return false;
    }

    let doesAnyValueExist = false;
    const counts = c?.[page]?.inputs?.reduce((acc, item) => {
    // check if there is anything to edit
      if (item.value !== null && item.value !== undefined) {
        doesAnyValueExist = true;
      }

      // check if there is atleast one optional
      if (!item?.required) {
        acc.optional++;
      }

      // check if there is atleast one identification
      if (item?.identification) {
        acc.identification++;
      }
      return acc;
    }, {
      optional: 0,
      identification: 0
    });

    // show: if identification is there, and its initialized(since no prefilled value is possible without identification being filled as it is always required)
    if (counts.identification > 0) {
      return true && doesAnyValueExist;
    }
    // show: if optional is there, and state is below in_progress
    if (counts.optional > 0 && [statesEnum.NOT_INITIALIZED, statesEnum.IN_PROGRESS].includes(form?.state)) {
      return true && doesAnyValueExist;
    }
    // dont show
    return false;
  };


  const constructFields = (p, editing = false) => {
    setError((zz) => ([...zz, {}]));
    let tempDisabledFields = {};
    const tempIdentificationFields = [];
    const tempNonVerificationFields = [];
    const fields = c?.[p]?.inputs?.reduce((acc, item) => {
      console.log('item: ', item, !item.value && !item?.isRequired && form?.state !== statesEnum.NOT_INITIALIZED);


      if (!item?.alwaysEnabled) {
        if (item.requiredVerificationFieldList) {
          if (form?.state !== statesEnum.NOT_INITIALIZED) {
            tempDisabledFields = { ...tempDisabledFields, [item.fieldName]: true };
          }
        } else {
          if (!editing) {
            if (!edit && form?.state !== statesEnum.NOT_INITIALIZED) {
              tempDisabledFields = { ...tempDisabledFields, [item.fieldName]: true };
            }
          }
        }
      }
      if (!item?.requiredVerificationFieldList && item?.required && !item?.identification &&
        form?.state !== statesEnum.NOT_INITIALIZED && page === 0) {
        tempDisabledFields = { ...tempDisabledFields, [item.fieldName]: true };
      }

      acc[item.fieldName] = item.value || '';
      if (item.validationRequired) {
        tempIdentificationFields.push(item);
      }

      if (!item.requiredVerificationFieldList) {
        tempNonVerificationFields.push(item);
      }


      return acc;
    }, {
      privateData2: {
      }
    });
    setNonVerificationFields(tempNonVerificationFields);
    setIdentificationInfo(tempIdentificationFields);
    setDisabledFields(tempDisabledFields);
    setStaleData(fields);
    return {
      fields,
      identificationFields: tempIdentificationFields,
      nonVerificationFields: tempNonVerificationFields
    };
  };
  useEffect(() => {
    const recordsSetTemp = constructFields(page);
    setRecordsSet([recordsSetTemp.fields]);
    // handleSubmitButtonDisability(recordsSetTemp.fields, recordsSetTemp.identificationFields);
    setBgvOpted(false);
    setMarkRecordsAsDone({});
    // updateStore('fieldContent', temp);
  }, [c, page]);


  const updateField = (fieldName, event, indexOfRecordSet, makeUpperCase = false, deleteThisKey = false) => {
    if (makeUpperCase) {
      event = convertEventValueToUpperCase(event);
    }
    const temp1 = { ...recordsSet[indexOfRecordSet] };
    if (!deleteThisKey) {
      temp1[fieldName] = event.target.value;
    } else {
      delete temp1[fieldName];
    }
    const temp2 = [...recordsSet];
    console.log('TEMP:1', temp2, temp1);
    temp2[indexOfRecordSet] = temp1;
    console.log('TEMP:2', temp2);
    setRecordsSet(temp2);
    // handleSubmitButtonDisability(temp1);
    // updateStore('fieldContent', temp);
  };

  console.log('recordsSet:formResponses::', recordsSet, formResponses, disabledFields, identificationInfo, edit);

  const sendToProcess = async (bodyToSend, basicDetailsValues) => {
    return axiosBaseInstance(`${c[page]?.api?.endpoint}`, {
      method: c[page].api.method.toUpperCase(),
      data: bodyToSend,
      ...getAxiosHeader({})
    }).then(async (res) => {
      console.log('response', res);
      if (res && res?.data?.data) {
        setBodySentForSendToProcess({ bodyToSend, basicDetailsValues });
        setLoading(false);
        if (res?.data?.data?.userInputIdentificationValidation) {
          const temp = { ...formResponses };
          temp[0] = {
            response: res.data.data,
            input: basicDetailsValues
          };
          setFormResponses(temp);
          setPage(1);
          return false;
        }
        return true;
      }
    });
  };
  const updateTheIdentificationValues = async (bodyToSend, basicDetailsValues) => {
    return updateBasicDetails(bodyToSend).then((res) => {
      if (res && res?.data?.data) {
        setBodySentForUpdateTheIdentificationValues({ bodyToSend, basicDetailsValues });

        if (res?.data?.data?.userInputIdentificationValidation) {
          const temp = {};
          temp[0] = {
            response: res.data.data,
            input: basicDetailsValues
          };
          setFormResponses(temp);
          setPage(1);
        }
        setLoading(false);
        return true;
      }
      setLoading(false);
      return false;
    }).catch((err) => {
      console.log('error', err);
      setLoading(false);
      return false;
      // window.snackbar('error', err?.response?.data?.message);
    });
  };

  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      padding: '0 1em',
      margin: 0
    }}
    title="Please enter your details below">
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        padding: '1em',
        borderRadius: '8px',
        margin: '0em 0 0em 0',
        flexWrap: 'nowrap',
        height: '100%'
      }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          flexWrap: 'nowrap',
          borderRadius: '8px',
          margin: '0em 0 0em 0',
          height: '100%'
        }}>

        <ComponentToRender
          indexOfRecordSet={0}
          name={data?.additionalInfo?.name}
          inputs={c?.[page]?.inputs}
          formResponses={formResponses}
          updateField={updateField}
          error={error[0]}
          // progress={progress[0]}
          recordsSet={recordsSet[0]}
          setError={setError}
          disabledFields={disabledFields}
          resendApiTrigger={async () => {
            if (edit) {
              await updateTheIdentificationValues(bodySentForUpdateTheIdentificationValues?.bodyToSend, bodySentForUpdateTheIdentificationValues?.basicDetailsValues);
            } else {
              await sendToProcess(bodySentForSendToProcess?.bodyToSend, bodySentForSendToProcess?.basicDetailsValues);
            }
          }} />


        <BottomComponent>
          {Object.keys(disabledFields)?.length != c?.[page]?.inputs?.length ?
            <>
              <Grid container sx={{ padding: '0 0 0 2em' }}>
                <FormControlLabel
                  control={<Checkbox
                    checked={checked}
                    onClick={() => setChecked(!checked)} sx={{ paddingLeft: 0 }} />}
                  label={(<Typography variant="body2" sx={{ textAlign: 'left' }}>
                    Information once entered cannot be changed. Hence I have carefully reviewed it.
                    {/* {c?.[0]?.inputs?.map((x) => x.label).join(', ')} */}
                    {' '}
                    {/* document and retain the details for internal purposes. */}

                  </Typography>)} />
              </Grid>
              <LoadingButton
                disabled={!checked}
                loading={loading}
                variant="contained" color="primary"
                sx={{ width: '96%', margin: '1em auto' }}
                datacy="submit"
                onClick={async () => {
                  const tempErr = {};
                  const basicDetailsValues = [];
                  const verificationFields = {};
                  setLoading(true);
                  try {
                    const recordsSetTrimmed = trimFields(recordsSet);
                    console.log('recordsSetTrimmed::::', recordsSetTrimmed);
                    if (recordsSetTrimmed[0]) {
                      c?.[page]?.inputs?.forEach((x) => {
                        if (x.required && (x.validation?.fn ?
                          !(eval(x?.validation?.fn)(recordsSetTrimmed[0][x.fieldName])) : !recordsSetTrimmed[0][x.fieldName]
                        )) {
                          tempErr[x.fieldName] = { message: x.validation.message };
                          return false;
                        }
                        let val = recordsSetTrimmed[0][x.fieldName];
                        if (x.inputType === 'date') {
                          if (isDateValid(val)) {
                            console.log('val::', val);
                            val = displayTime(val, x.format);
                          }
                        }
                        if (x.requiredVerificationFieldList) {
                          verificationFields[x.fieldName] = val;
                        } else {
                          basicDetailsValues.push({ label: x.fieldName, value: val });
                        }
                        return true;
                      });

                      setError([tempErr]);

                      if (Object.keys(tempErr)?.length <= 0) {
                        const bodyMaker = eval(c[page].api.body);
                        console.log('basicDetailsValues', basicDetailsValues);
                        const body = bodyMaker(page === 0 ? {
                          userInputList: basicDetailsValues,
                          ...(Object.keys(verificationFields).length > 0 ? {
                            verificationFields: verificationFields
                          } : {})
                        } : basicDetailsValues, formResponses);

                        const bodyToSend = {
                          ...body,
                          'txnId': searchedQuery?.txnId,
                          'orgId': searchedQuery?.orgId,
                          'verificationName': currentCredentialUnderFetch?.key ?? null,

                        };

                        if (edit && page === 0) {
                          let shouldHitProcess = true;
                          let val = null;
                          if (identificationInfo?.length > 0) {
                            val = basicDetailsValues.find((x) => x.label === identificationInfo[0].label);
                            if (val?.value !== staleData[identificationInfo[0].label]) {
                              shouldHitProcess = await updateTheIdentificationValues({
                                identificationValue: val?.value,
                                orgId: searchedQuery?.orgId,
                                txnId: searchedQuery?.txnId
                              }, basicDetailsValues);
                            }
                          }
                          // else if (val && basicDetailsValues.length === 1) {
                          //   // window.snackbar('error', `Please change the value of ${identificationInfo[0].label} field`);
                          // }
                          const areNonIdentificationInputsPresent = identificationInfo?.length > 0 ?
                            basicDetailsValues?.some((x) => x.label !== identificationInfo[0].label) :
                            basicDetailsValues?.length > 0;

                          if (shouldHitProcess && (areNonIdentificationInputsPresent)) {
                            await sendToProcess(bodyToSend, basicDetailsValues);
                          }
                          if (val ? val?.value === staleData[identificationInfo[0].label] : true) {
                            navigate(-1);
                          }
                          // setEdit(false);
                        } else {
                          const done = await sendToProcess(bodyToSend, basicDetailsValues);
                          if (done) { navigate(-1); }
                        }
                      }
                    }
                  } catch (err) {
                    console.log('error', err);
                    setLoading(false);
                  }
                  setLoading(false);
                }}>
                {/* {c[page]?.submitButtonText} */}
                Submit
              </LoadingButton>
            </> : <>

              {shouldShowEditButton() ? <Button
                onClick={async () => {
                  setEdit(true);
                  const recordsSetTemp = constructFields(page, true);
                  setRecordsSet([recordsSetTemp.fields]);
                  // handleSubmitButtonDisability(recordsSetTemp.fields, recordsSetTemp.identificationFields, true);
                }}
                variant="outlined"
                color="primary"
                sx={{ mb: '1em' }}>
                Edit
              </Button> : null}
              <Button
                variant="contained"
                onClick={() => navigate(-1)}>
                Back
              </Button>
            </>}


        </BottomComponent>
      </Grid>
    </Grid>
  </SimpleBackground>;
}

const ComponentToRender = ({ indexOfRecordSet, name, inputs, formResponses,
  updateField, setError, error, progress, recordsSet, onRemove = null, disabledFields, resendApiTrigger = () => { } }) => {
  console.log('disabledFields::', formResponses, '-', disabledFields);
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{
          padding: '1em',
          borderRadius: '8px',
          margin: '1em 0 0em 0',
          // maxHeight: '100vh',
          width: '100%',
          position: 'relative'
        }}>
        {onRemove ? <IconButton
          sx={{ position: 'absolute', top: '0px', right: '0.5em' }}
          onClick={() => {
            onRemove();
          }}>
          <CancelOutlined />
        </IconButton> : null}
        {inputs?.map((input, j) => <Grid
          key={`${input.fieldName}-${j}`}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{

          }}>
          {/* <Typography
          variant="body1" sx={{
            fontWeight: 500, margin: '0em 0'
          }}>
          {input.label}
        </Typography> */}

          {input?.description ? <Typography
            variant="body1" sx={{
              fontWeight: 500, margin: '1em 0',
              color: '#888888'
            }}>
            {eval(input?.description)(formResponses)}
          </Typography> : null}
          <ConstructFormInputs
            input={input}
            j={j}
            indexOfRecordSet={indexOfRecordSet}
            updateField={updateField}
            setError={setError}
            error={error}
            progress={progress}
            recordsSet={recordsSet}
            disabled={disabledFields[input.fieldName]}
          />
          {input?.extraComponent ?
            <>
              {input?.extraComponent?.map((el) => sideUi({
                type: el,
                resendApiTrigger: async () => { await resendApiTrigger(); }
              }))
              }
            </> : null}
        </Grid>)
        }
      </Grid>

    </>);
};
